<template>
    <headers></headers>
    <div class="top-gap"></div>
    <div class="stack">
        <div>
            <b>Community rewards</b>
            <span>{{ $t('award.communityDesc') }}</span>
        </div>
    </div>
    <div class="award max-width">
        <div class="left">
            <div>
                <span>{{ $t('award.communityList1') }}</span>
                <b>{{ Util.showAmount(bonus.total) }} USDT</b>
            </div>
            <div>
                <span>{{ $t('award.communityList2') }}</span>
                <b>{{ Util.showAmount(bonus.withdraw) }} USDT</b>
            </div>
        </div>
        <div class="right">
            <div>
                <p>{{ $t('award.communityList3') }}</p>
                <b>{{ Util.showAmount(bonus.remain) }} USDT</b>
            </div>
            <button v-if="bonus.remain - 0 > 0" @click="withdraw()">{{ $t('award.communityBut') }}</button>
            <button class="disabled" v-else>{{ $t('award.communityBut') }}</button>
        </div>
    </div>
    <div class="list-lay">
        <div class="title">{{ $t('award.record') }}</div>
        <div class="list max-width">
            <div v-for="(item,index) in bonuses.list" :key="index">
                <div class="day">
                    <span>{{ item.date }}</span>
                    <b>{{ $t('award.total') }} {{ item.total }} USDT</b>
                </div>
                <div>
                    <span>{{ $t('award.listType1') }}</span>
                    <span>{{ item.share }} USDT</span>
                </div>
                <div>
                    <span>{{ $t('award.listType2') }}</span>
                    <span>{{ item.team }} USDT</span>
                </div>
                <div>
                    <span>{{ $t('award.listType4') }}</span>
                    <span>{{ item.dividend }} USDT</span>
                </div>
            </div>
        </div>
        <div class="more" v-if="bonuses.page < bonuses.totalPage" @click="getNextBonusList()">{{ $t('lp.more') }}</div>
        <no-data img="no-lp" v-if="bonuses.totalRecord === 0"></no-data>
    </div>
    
    <bases></bases>
</template>
<script setup>
    import headers from '@/components/header'
    import bases from '@/components/base'
    import {ref, reactive, onMounted, computed, watch} from 'vue';
    import { useRouter } from 'vue-router';

    import { queryBonus, queryBonusList, withdrawBonus } from '@/api/bonus'

    import {memberStore} from "@/store/member"
    import {walletStore} from "@/store/wallet"
    import {webStore} from "@/store/web"
    import i18n from "@/i18n";
    import {ElMessage} from "element-plus";
    import {call, getContractAddress, send} from "@/contract/web3-util";
    import Util from "@/utils/common-util";
    import Web3 from "web3";
    import {end, start} from "@/api/loading";

    const mStore = memberStore()
    const wStore = walletStore()
    const wbStore = webStore()

    const router = useRouter();

    const bonus = reactive({
      total: 0,
      withdraw: 0,
      remain: 0
    })

    const bonuses = reactive({
      page: 1,
      pageSize: 0,
      totalPage: 0,
      totalRecord: 0,
      list: []
    })

    const stackList = reactive([
        { num: '0.1000', unit: 'SOL'},
        { num: '200,000.0000', unit: 'SOL'},
        { num: '200,000.0000', unit: 'SOLFI'},
    ])

    const list = reactive([
        {date: '2023-03-25',amount: '100.0000',type1: '100.0000',type2: '100.0000',type3: '100.0000',type4: '100.0000'}
    ])

    const goPage = (url) => {
        router.push({
            path: url
        })
    }

    function getBonus() {
      if (wbStore.isLogin()) {
        queryBonus().then(result => {
          if (Util.isDefine(result) && result.data.code === 1) {
            const data = result.data.data
            bonus.total = data.stakeBonusTotal
            bonus.withdraw = data.stakeBonusWithdraw
            bonus.remain = data.stakeBonusRemain
          }
        })
      }
    }

    function getBonusList() {
      if (!wbStore.isLogin()) {
        return
      }

      bonuses.list = []
      bonuses.page = 1
      bonuses.pageSize = 0
      bonuses.totalPage = 0
      bonuses.totalRecord = 0
      getBonuses()
    }
    function getNextBonusList() {
      if (!wbStore.isLogin()) {
        return
      }

      if (bonuses.page < bonuses.totalPage) {
        bonuses.page++
      } else {
        return
      }

      getBonuses()
    }

    function getBonuses() {
      if (!wbStore.isLogin()) {
        return
      }

      queryBonusList({page: bonuses.page, pageSize: 6}).then(function (result) {
        if (Util.isDefine(result) && result.data.code === 1) {
          const data = result.data.data.pagingList
          bonuses.page = data.currPage
          bonuses.pageSize = data.pageSize
          bonuses.totalPage = data.totalPage
          bonuses.totalRecord = data.totalRecord

          const list = data.resultList
          for (let i = 0; Util.isDefine(list) && i < list.length; i++) {
            const a = list[i]
            const bonus = {}
            // bonus.date = a.date
            bonus.date = a.date
            bonus.share = Util.numFormat4Floor(a.share1 + a.share2 - 0)
            bonus.team = Util.numFormat4Floor(a.team - 0)
            bonus.same = Util.numFormat4Floor(a.same - 0)
            bonus.dividend = Util.numFormat4Floor(a.dividend - 0)
            bonus.total = Util.numFormat4Floor(a.share1 + a.share2 + a.team + a.same + a.dividend - 0)

            bonuses.list.push(bonus)
          }
        }
      })
    }

    const withdraw = () => {
      if (!wbStore.isLogin()) {
        ElMessage({
          message: i18n.global.t('alert.connectWallet'),
          type: 'warning'
        })
        return
      }

      if (bonus.remain - 0 <= 0) {
        return
      }

      start()
      withdrawBonus().then(result => {
        if (Util.isDefine(result) && result.data.code === 1) {
          const orderSerial = result.data.data.orderSerial
          const bonus = result.data.data.amount + ''
          const signTime = result.data.data.signTime
          const sign = result.data.data.sign

          send('stake-v2', 'withdrawBonus', [Web3.utils.toWei(bonus), signTime, orderSerial, sign]).then(receipt => {

            if (receipt.status) {
              ElMessage({
                message: i18n.global.t('alert.success'),
                type: 'success'
              })

              getBonus()
            } else {
              ElMessage({
                message: i18n.global.t('alert.failed'),
                type: 'error'
              })
            }
            end()
          }).catch(function(error) {
            console.error(error)
            end()
            ElMessage({
              message: i18n.global.t('alert.failed'),
              type: 'error'
            })
          })
        }
      }).catch(function(error) {
        console.error(error)
        end()
      })
    }

    onMounted(() => {
      getBonus()
      getBonusList()
    })

    const curAccount = computed(()=>{
      return wStore.curAccount
    })
    watch(curAccount, (newVal, oldVal) =>{
      if (newVal) {
        if (oldVal) {
          alert(i18n.global.t('alert.reLogin'))
        }

        wbStore.accountLogin().then((result) => {
          wbStore.setLoginState(true)
          mStore.queryMemberInfo()

          getBonus()
          getBonusList()

        }).catch((reason) => {
          wbStore.setLoginState(false)
          console.error('login failed')
        })
      }
    })
</script>
<style scoped src="./css/index.less" lang="less">
</style>
